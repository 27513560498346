<template>
  <div>
    <b-card
      class="mt-4 border-0"
      body-class="p-2 bg-gray-200 text-center"
    >
      <b-button
        v-if="isReportFull"
        variant="success"
        size="lg"
        @click="dlProduct()"
      >
        Scarica il report completo
      </b-button>
      <div
        v-else-if="isReportInProgress"
        class="d-flex justify-content-center align-items-center"
      >
        <b-button
          variant="success"
          size="lg"
          disabled
        >
          Report in creazione
        </b-button>
        <b-button
          variant="link"
          class="ms-3"
          @click="routeTo('/user-analysis-history')"
        >
          Vai alle richieste
        </b-button>
      </div>
      <b-button
        v-else
        variant="info"
        size="lg"
        @click="buyProduct()"
      >
        Acquista il report completo
      </b-button>
    </b-card>
    <product-wizard-modal
      :id="wizardModalId"
      :request-type="requestType"
      :request-product="itemKey"
      :product-description="productDescription"
      :entity-type="entityTypeData"
      :profile-entity="profileEntity"
    />
  </div>
</template>

<script>
import { getEntityApiBase, openPrivateDocument } from '../../utils/documents';
import { isNotEmpty } from '../../utils/validators';

const ProductWizardModal = () => import('../productTable/ProductWizardModal.vue');

export default {
  name: 'ProfileCta',
  components: { ProductWizardModal },
  props: {
    requestType: String,
    profileEntity: Object,
    modalId: String,
    entityType: String,
    isReportFull: Boolean,
    isReportInProgress: Boolean,
    reportDocId: String,
  },
  data() {
    return {
      itemKey: 'pr_company_report_crp',
      productDescription: 'Report CRP',
      entityTypeData: this.entityType || '',
      wizardModalId: this.modalId || 'ctaWizardModal',
    };
  },
  computed: {},
  methods: {
    buyProduct() {
      this.$bvModal.show(this.wizardModalId);
    },
    dlProduct() {
      if (isNotEmpty(this.reportDocId)) {
        openPrivateDocument(this.reportDocId, getEntityApiBase('USERANALYSIS'));
      }
    },
    routeTo(path) {
      this.$router.push(path);
    },
  },
};
</script>

<style scoped>

</style>
